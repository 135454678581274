<template>
  <div>
    <el-divider content-position="left">标签详情</el-divider>
    <el-descriptions
      class="margin-top"
      title=""
      :column="4"
      :size="medium"
       direction="horizontal"
      border
      >
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            生成时间
          </template>
          {{labelInfo.createTime}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            防伪码标签（套）
          </template>
          {{labelInfo.labelCount}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            起始标签
          </template>
          {{labelInfo.startLabel}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            结束标签
          </template>
          {{labelInfo.endLabel}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            物料代码
          </template>
          {{labelInfo.model.materialCode}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            型号
          </template>
          {{labelInfo.model.name}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            类型
          </template>
          {{labelInfo.model.type.title}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            制造商
          </template>
          {{labelInfo.manufacturer.name}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            制造商代码
          </template>
          {{labelInfo.manufacturer.code}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            下单时间
          </template>
          {{labelInfo.order &&labelInfo.order.orderTime}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            订单批次
          </template>
          {{labelInfo.order&&labelInfo.order.orderBatch}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            供应商
          </template>
          {{labelInfo.order&&labelInfo.order.supplier.name}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            分发时间
          </template>
          {{labelInfo.distributeTime}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            物流单号
          </template>
          {{labelInfo.logisticsNumber}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            申请人
          </template>
          {{labelInfo.labelApply.creatorName}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            接收人
          </template>
          {{labelInfo.consignee}}
        </el-descriptions-item>
    </el-descriptions><br>
  </div>
</template>

<script>
import labelInfoApi from '@/apis/label/info'
export default {
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      labelInfo: {
        createTime: undefined,
        consignee: undefined,
        logisticsNumber: undefined,
        distributeTime: undefined,
        order: {
          orderBatch: undefined,
          supplier: {
            name: undefined
          },
          orderTime: undefined
        },
        manufacturer: {
          name: undefined,
          code: undefined
        },
        model: {
          materialCode: undefined,
          name: undefined,
          type: {
            value: undefined,
            title: undefined
          }
        },
        labelApply:{},
        endLabel: undefined,
        startLabel: undefined,
        labelCount: undefined
      }
    }
  },
  created() {
    this.getInfo()
  },
  mounted(){
  },
  methods:{
    getInfo(){
      labelInfoApi.getById(this.id).then(resp=>{
        this.labelInfo = resp.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>